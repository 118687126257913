.promotion /deep/ .el-dialog__body {
  .top {
    .header-text {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 35px;
    }
    li {
      word-break: break-word;
      line-height: 1.6;
      font-size: 16px;
      padding-bottom: 15px;
      text-align: justify;
    }
  }

  .tncLabel {
    display: inline !important;
    color: $future-blue;
  }
  .btn-box {
    text-align: center;
    .el-button {
      width: 180px;
      height: 60px;
    }
  }
}
