/deep/ .el-dialog__wrapper {
  .el-dialog {
    background: none;
  }
}

/deep/ .el-dialog__header {
  display: none;
}

/deep/ .el-dialog__body {
  padding: 0 0 10px;
  text-align: center;
  word-break: normal;

  p {
    font-size: 16px;
    color: rgb(44, 44, 44);
    line-height: 1.5;
    text-align: center;
    padding: 0 20%;
  }

  .close-image {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    color: $white;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
  }

  .dialog-header {
    position: relative;
    background-color: $primary;
    line-height: 96px;
    height: 96px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .dialog-body {
    background-color: $white;
    padding: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.cb-dialog /deep/ {
  .common-dialog-wrapper .dialog.el-dialog__wrapper .el-dialog {
    box-shadow: none;
    .el-dialog__body {
      .dialog-body {
        padding: 0;
        border-radius: 8px;

        .cb-dialog-header {
          position: relative;
          background-color: $primary;
          line-height: 96px;
          height: 96px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        .cb-dialog-body {
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
