
              @import "@/assets/css/variables.scss";
            

































































































@import '@/assets/css/components/dialog/conditionDialog.scss';

.cb-dialog-body /deep/ {
  .text-congratuate {
    font-weight: 900;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .text-result {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .submit-btn {
    margin-bottom: 16px;
    min-width: 180px;
  }
}

.common-dialog-wrapper /deep/ {
  .dialog-body {
    padding: 0 0 45px 0;
    height: 256px;
    max-height: 310px;
  }
  .el-dialog__body p {
    padding: 0 4% !important;
  }
}

.common-dialog-wrapper.context-with-btn /deep/ {
  .dialog-body {
    height: auto;
    max-height: none;
  }
}

@media (min-width: 550px) {
  .common-dialog-wrapper /deep/ {
    .dialog-body {
      padding: 0 35px 45px 30px;
    }
    .el-dialog__body p {
      padding: 0 20% !important;
    }
  }
}
