
              @import "@/assets/css/variables.scss";
            


































































































































@import '@/assets/css/components/promotion/agreeTnc.scss';
.text-uppercase {
  text-transform: uppercase;
}

/deep/ .el-dialog__body .top li {
  padding-bottom: 20px !important;
}

.common-dialog-wrapper /deep/ {
  .dialog-body {
    padding: 0 0 45px 0;
  }
}

/deep/ .el-button {
  background-color: $future-blue;
  color: white;
  &.is-disabled {
    background-color: $future-blue;
    color: white;
  }
}
